<template>
	<div class="container">
		<div class="row header">
			<div class="col">
				<h3>Welcome, {{ userName }}!</h3>
			</div>
			<div class="col text-right">
				<v-btn
					class="admin-primary-button primary-contrast-background shadow-none"
					@click="goTo('NewCourse')"
					>New Course</v-btn
				>
			</div>
		</div>

		<div class="row my-drafts">
			<div class="col">
				<h5>My Drafts</h5>
				<table class="table table-striped brand">
					<thead>
						<tr>
							<th class="col-2"></th>
							<th class="col-3">Course Title</th>
							<th class="col-2 text-center">Last Edited</th>
							<th class="col-2 text-center">Date Created</th>
							<th class="col-2"></th>
						</tr>
					</thead>
					<tbody>

						<RowData
							v-for="course in draftCourses.slice(0, 3)"
							:key="course.id"
							:image="course.images"
							:name="course.title"
							:dateLeft="`${Math.ceil(
								Number(new Date() - new Date(course.updated_at)) /
									1000 /
									60 /
									60 /
									24
							)} days ago`"
							:dateRight="`${new Date(
								course.created_at.slice(0, 10)
							).toLocaleDateString()}`"
							:buttons="[
								{
									title: 'Edit',
									action: () => editCourse(course.id)
								}
							]"
						/>

					</tbody>
				</table>

				<p class="text-right"><a @click="goTo('Courses')" class="no-decoration text-brand font-weight-bold">See All Drafts</a></p>
			</div>
		</div>

		<div class="row published-courses">
			<div class="col">
				<h5>Recently Published Courses</h5>
				<table class="table table-striped brand">
					<thead>
						<tr>
							<th class="col-2"></th>
							<th class="col-3">Course Title</th>
							<th class="col-2 text-center">Date Published</th>
							<th class="col-2 text-center"></th>
							<th class="col-2"></th>
						</tr>
					</thead>
					<tbody>

						<RowData
							v-for="course in publishedCourses.slice(0, 2)"
							:key="course.id"
							:image="course.images"
							:name="course.title"
							:dateLeft="`${Math.ceil(
								Number(new Date() - new Date(course.updated_at)) /
									1000 /
									60 /
									60 /
									24
							)} days ago`"
							:dateRight="` `"
							:buttons="[
								{
									title: 'Analytics',
									action: () => showAnalytics(course.id)
								},
								{
									title: 'Edit',
									action: () => editCourse(course.id)
								}
							]"
						/>

					</tbody>
				</table>

				<p class="text-right"><a @click="goTo('Courses', { tab: 'published' })" class="no-decoration text-brand font-weight-bold">See All Courses</a></p>
			</div>
		</div>
	</div>
</template>

<script>
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import { mapGetters, mapState } from "vuex";
import RowData from "../../../components/RowData.vue";
import AdminDefaultDescription from "@/components/AdminDefaultDescription.vue";
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";

export default {
	name: "AdminDashboard",
	components: {
		RowData,
		AdminDefaultHeader,
		AdminDefaultDescription,
		AdminDefaultSubheader
	},
	computed: {
		...mapState({
			userName: state => state.user.firstName
		}),
		...mapGetters(["publishedCourses", "draftCourses"])
	},
	methods: {
		goTo(name, query) {
			this.$router.push({ name: name, query: query });
		},
		editCourse(id) {
			this.$router.push({
				name: "CourseEditor",
				params: { id: id }
			});
		},
		showAnalytics(id) {
			this.$router.push({
				name: "CourseAnalytics",
				params: { id: id }
			});
		}

	},
	created() {}
};
</script>
