var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row header"},[_c('div',{staticClass:"col"},[_c('h3',[_vm._v("Welcome, "+_vm._s(_vm.userName)+"!")])]),_c('div',{staticClass:"col text-right"},[_c('v-btn',{staticClass:"admin-primary-button primary-contrast-background shadow-none",on:{"click":function($event){return _vm.goTo('NewCourse')}}},[_vm._v("New Course")])],1)]),_c('div',{staticClass:"row my-drafts"},[_c('div',{staticClass:"col"},[_c('h5',[_vm._v("My Drafts")]),_c('table',{staticClass:"table table-striped brand"},[_vm._m(0),_c('tbody',_vm._l((_vm.draftCourses.slice(0, 3)),function(course){return _c('RowData',{key:course.id,attrs:{"image":course.images,"name":course.title,"dateLeft":`${Math.ceil(
							Number(new Date() - new Date(course.updated_at)) /
								1000 /
								60 /
								60 /
								24
						)} days ago`,"dateRight":`${new Date(
							course.created_at.slice(0, 10)
						).toLocaleDateString()}`,"buttons":[
							{
								title: 'Edit',
								action: () => _vm.editCourse(course.id)
							}
						]}})}),1)]),_c('p',{staticClass:"text-right"},[_c('a',{staticClass:"no-decoration text-brand font-weight-bold",on:{"click":function($event){return _vm.goTo('Courses')}}},[_vm._v("See All Drafts")])])])]),_c('div',{staticClass:"row published-courses"},[_c('div',{staticClass:"col"},[_c('h5',[_vm._v("Recently Published Courses")]),_c('table',{staticClass:"table table-striped brand"},[_vm._m(1),_c('tbody',_vm._l((_vm.publishedCourses.slice(0, 2)),function(course){return _c('RowData',{key:course.id,attrs:{"image":course.images,"name":course.title,"dateLeft":`${Math.ceil(
							Number(new Date() - new Date(course.updated_at)) /
								1000 /
								60 /
								60 /
								24
						)} days ago`,"dateRight":` `,"buttons":[
							{
								title: 'Analytics',
								action: () => _vm.showAnalytics(course.id)
							},
							{
								title: 'Edit',
								action: () => _vm.editCourse(course.id)
							}
						]}})}),1)]),_c('p',{staticClass:"text-right"},[_c('a',{staticClass:"no-decoration text-brand font-weight-bold",on:{"click":function($event){return _vm.goTo('Courses', { tab: 'published' })}}},[_vm._v("See All Courses")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"col-2"}),_c('th',{staticClass:"col-3"},[_vm._v("Course Title")]),_c('th',{staticClass:"col-2 text-center"},[_vm._v("Last Edited")]),_c('th',{staticClass:"col-2 text-center"},[_vm._v("Date Created")]),_c('th',{staticClass:"col-2"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"col-2"}),_c('th',{staticClass:"col-3"},[_vm._v("Course Title")]),_c('th',{staticClass:"col-2 text-center"},[_vm._v("Date Published")]),_c('th',{staticClass:"col-2 text-center"}),_c('th',{staticClass:"col-2"})])])
}]

export { render, staticRenderFns }